// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-icons: "Font Awesome 5 Free";
$font-size-base: 0.9rem;
$line-height-base: 1.4;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$kleur-geel: #fecb47;
$kleur-orange: #f3912f;
$kleur-zachtCyan: #5bc0de;
$kleur-zachtCyan2: #46b8da;
$kleur-cyan: #23c1f4;
$kleur-cyan2: #18c0f4;
$kleur-sterkCyan: #31b0d5;
$kleur-sterkCyan2: #269abc;
$kleur-donkerBlauw: #152c49;
$kleur-donkersteBlauw: #111b28;
$kleur-grijsBlauw: #c2c4cb;
$kleur-grijsBlauw2: #aeb4bb;
$kleur-grijsCyan: #c8c9d0;
$kleur-sterkRood: #b92c28;
$kleur-sterkRood2: #c9302c;
$kleur-offwhite: #fdfdfd;
$kleur-blauwgroen: #4dc0b5;

$kleur-lichtGrijs: #F8F9F8;
$kleur-lichtGrijs2: #ebebeb;
$kleur-lichtGrijs3: #dee2e6;
$kleur-lichtGrijsBlauw: #dee2e6;
$kleur-felCyan: #24c1f6;

$kleur-statusSucces: #31bc8d;
$kleur-statusInfo: #23c1f4;
$kleur-statusWarning: #ffb733;
$kleur-statusDanger: #ff6060;

$kleur-cyanDonker: #0599b0;
$kleur-bruin: #d6af80;
$kleur-magenta: #ca73cd;
$kleur-cyanBlauw: #105e9a;
  /**
  * Calculate de REM voor fontsize, zodat de gebruikers ingestelde browser-fontsize gerespecteerd blijft
  * Gebruik deze function om pixels te converteren naar REM
  * Voorbeeld:
  *   font-size: calcRem(20px);
   */
@function calcRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}