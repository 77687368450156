@import "../../../../sass/variables";

.box {
  max-width: 1150px;
  margin: 0 auto;

  .boxForm {
    display: flex;
    gap: 12px;
    max-width: 600px;
    margin: 0 auto;

    .boxInputs {

      .rowInputs {
        max-width: 400px;
        margin: 0 auto;
        align-items: center;
        display: flex;
        gap: 12px;


      }

      .toelichtingBox {
        max-width: 400px;
        margin: 0 auto;
        padding-top: 16px;
        padding-bottom: 50px;

        .toelichtingLabel {
          font-size: 0.85rem;
          display: flex;
          gap: 5px;
          flex-direction: row;
          text-decoration: underline;
          cursor: pointer;
          padding-bottom: 12px;
          padding-left: 2px;

          &:hover {
            color: $kleur-cyan;
          }
        }

        .toelichting {
          overflow: hidden;
          transition: max-height .5s;
          display: flex;
          max-height: 0;
          max-width: 600px;

          &.open {
            max-height: 250px;
          }
        }
      }

    }

    .btnbox {
      transition: margin-top .5s;

      &.open {
        margin-top: 112px
      }
    }

    .boxFormInner {
      width: 100%;
      padding-bottom: 12px;

      input, textarea, button {
        margin-bottom: 12px;
      }

      button {
        width: 100%;
        text-align: center;
      }
    }
  }

  .outroImages {
    text-align: center;
    padding: 28px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    max-width: 1150px;

    img {
      filter: grayscale(100%);

      &:hover {
        filter: grayscale(0%);
      }
    }
  }

  .trust_logos {
    text-align: center;
    padding: 28px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    max-width: 1150px;
  }

  .mkgeu_titel .pt-5 {
    margin-top: 100px !important;
  }

  @media (max-width: 600px) {
    .boxForm {
      flex-direction: column;
      padding: 0 12px;

      .boxFormInner {
        padding-bottom: 0;
      }
    }
    .outroImages {
      text-align: center;
      padding: 28px 0;
    }
  }


}