.componentWrapper {
  padding-top: 100px;
  padding-bottom: 150px;
  background-color: #111b28;
  //min-height: 780px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
  flex-direction: column;
  .componentHeader {
    color: #fff;
    font-weight: bold;
    font-style: italic;
    letter-spacing: -3px;
    text-align: center;
    margin-bottom: 48px;
  }

  .componentSpacer {
    height: 4px;
    width: 64px;
    background-color: #23c1f4;
  }

  .oplossingenContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    flex-direction: column;
    color: white;
    max-width: 750px;
    position: relative;

    .oplossingenItem {
      width: 100%;
      min-height: 150px;
      display: flex;
      justify-content: space-between;
      //align-items: center;
      padding: 10px;
      cursor: pointer;

      .contentContainer {
        color: white;
      }
      &:hover {
        transition: 300ms ease-in-out;

        .itemIcon {
          img {
            filter: invert(53%) sepia(98%) saturate(1038%) hue-rotate(164deg) brightness(106%) contrast(91%);
          }
        }

        .contentContainer {
          color: #23C1F4;
        }

        .arrow {
          svg {
            fill: #FECB47;
          }
        }

      }

      .itemIcon {
        min-width: 43px;
        min-height: 150px;
        margin-right: 25px;
        border-bottom: 2px solid white;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 70px;
          height: 70px;
          filter: invert(1);
        }
      }

      .contentContainer {
        width: 100%;
        min-height: 150px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        margin-left: 25px;
        border-bottom: 2px solid white;

        .arrow {
          margin-left: auto;
          padding: 10px;
        }
      }


    }

    .arrowGrow {
      background-color: #FECB47;
      position: absolute;
      bottom: 0;
      left: 110px;
      width: 2px;
      height: 0px;

      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        left: -9px;
        top: -7px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 20px solid #FECB47;
        border-radius: 3px;
      }
    }

    .arrowTekst {
      position: absolute;
      bottom: -50px;
      left: 80px;
      color: #FECB47;
      font-size: 12px;
      text-align: center;

    }

    .arrowMove {
      transition: 1500ms ease-out;
      height: 92%;
    }
  }
}

@media (min-width: 500px) {

  .componentWrapper {
    .componentHeader{
      font-size: 50px;
    }
    .oplossingenContainer {
      .oplossingenItem:nth-child(1) {
        margin-left: 10px;
      }
      .oplossingenItem {
        .itemIcon {
          padding: 20px;

          img {
            width: 88px;
            height: 88px;
          }
        }

        .contentContainer {
          gap: 50px;
        }
      }

      .arrowGrow {
        left: 162px;

      }

      .arrowTekst {
        left: 128px;
      }

    }
  }
}
@media (max-width: 500px) {
  .arrowGrow {
    left: 100px !important;
  }
  .arrowTekst {
    left: 70px !important;
  }
}
@media (min-width: 900px) {

  .componentWrapper {
    .componentHeader{
      font-size: 73px;
    }
    .oplossingenContainer {
      .oplossingenItem {
        .itemIcon {
          padding: 20px;

          img {
            width: 88px;
            height: 88px;
          }
        }

        .contentContainer {
          gap: 50px;
          padding: 0 20px;

          .contentText {
            margin-right: 50px;
          }
        }
      }
    }
  }
}





