@import "../../../../sass/variables";


.branchesMenu {
  z-index: 10;
  margin-top: 10px;
  background-color: $kleur-donkersteBlauw;
  position: relative;
  padding: 300px 0;
  overflow: hidden;

  &:before {
    content: '';
    width: 100%;
    height: 150px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    z-index: -2;
  }

  &:after {
    content: '';
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: -2;
  }


  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 30px 20px;
    color: white;
    border-bottom: 1px solid;
    text-decoration: none;
    width: 100%;
    margin: 0 auto;
    font-weight: bold;
    font-style: italic;
    max-width: 750px;


    &:hover, &.active {
      color: $kleur-cyan;


      .pijl, .svgs {
        fill: $kleur-cyan !important;
      }
    }

    .svgs {
      width: 48px;
      fill: white !important;
    }

    .pijl {
      margin-left: auto;
      width: 20px !important;
      fill: white !important;

    }

    &:after {
      display: block;
      clear: both;
      content: ""
    }
  }

  .imgContainer1 {
    z-index: -1  !important;;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-40%, -0%);

    .partImages {
      width: 100%;
      max-width: 300px;
      height: auto;
    }
  }

  .imgContainer2 {
    display: none;
    position: absolute;
    top: 40px;
    left: -150px;
    opacity: 0.6;
    z-index: -3  !important;;

    .partImages {
      width: 100%;
      max-width: 450px;
      height: auto;
    }
  }

  .imgContainer3 {
    display: none;
    position: absolute;
    bottom: 0;
    left: -150px;
    opacity: 0.4;
    z-index: -2 !important;

    .partImages {
      width: 100%;
      max-width: 450px;
      height: auto;
    }
  }

  .imgContainer4 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: -160px;
    left: 50%;
    transform: translate(-35%, -92%);

    .partImages {
      width: 100%;
      max-width: 300px;
      height: auto;
      transform: rotate(20deg);
    }
  }

  .imgContainer5 {
    display: none;
    position: absolute;
    top: 500px;
    right: -100px;
    opacity: 0.4;
    transform: rotate(252deg);

    .partImages {
      width: 100%;
      max-width: 450px;
      height: auto;
    }
  }

}

@media(min-width: 420px) {
  .branchesMenu {
    //font-size: 1.1rem;
  }

}

@media(min-width: 450px) {
  .branchesMenu {
    //font-size: 1.2rem;
  }
}

@media(min-width: 550px) {
  .branchesMenu {
    //font-size: 1.3rem;

    .imgContainer1 {
      .partImages {
        max-width: 350px;
      }
    }

    .imgContainer4 {
      transform: translate(-35%, -70%);

      .partImages {
        max-width: 350px;
      }
    }
  }
}


@media(min-width: 700px) {
  .branchesMenu {
    //font-size: 1.4rem;

    a {
      .svgs {
        width: 60px !important;
      }

      .pijl {
        width: 35px !important;

      }
    }
  }
}

@media(min-width: 950px) {

  .branchesMenu {
    //font-size: 1.5rem;

    a {
      .pijl {
        width: 40px !important;
      }
    }

    .imgContainer1 {
      transform: translate(-40%, -8%);
      .partImages {
        max-width: 400px;
      }
    }

    .imgContainer4 {
      transform: translate(-30%, -35%);

      .partImages {
        max-width: 475px;
      }
    }

    .whiteRowBot {
      margin-top: 250px;
    }
  }
}

@media(min-width: 1250px) {

  .branchesMenu {
    //font-size: 1.5rem;

    a {
      padding: 30px 20px 30px 0;

      .pijl {
        width: 40px !important;

      }
    }

    .imgContainer1 {
      .partImages {
        max-width: 425px;
      }
    }

    .imgContainer2 {
      display: block;

      .partImages {
        max-width: 550px;
      }
    }

    .imgContainer3 {
      display: block;
      position: absolute;
      bottom: 80px;
      left: -225px;
      opacity: 0.4;

      .partImages {
        width: 100%;
        max-width: 450px;
        height: auto;
      }
    }

    .imgContainer5 {
      display: block;

      .partImages {
        max-width: 500px;
      }
    }
  }
}