.imgContentContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: #24C1F4;
  min-height: 900px;
}
.headerContentIC {
  width: 100%;
  z-index: 10;
}
.titelContentIC {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
}
.headerLandmap {
  font-weight: bold;
  font-style: italic;
  letter-spacing: -3px;
  font-size: 73px;
  text-align: center;
}
.headerOnderschrift {
  font-size: 24px;
  text-align: center;
  margin-bottom: 96px;
}
.itemsContentIC {
  display: flex;
  flex-direction: row;
  gap: 150px;
  margin-left: 350px;

  .itemsContentICItem {
    display: flex;
    flex-direction: column;
  }
}
.InputContentIC {
  display: flex;
  flex-direction: column;
  margin-left: 350px;
  padding-top: 32px;
  z-index: 10;
}
.inputContentItem {
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 3px;
}

.headerContentImgCont {
  position: absolute;
  right: 0;
  bottom: 0;
}
.linktozin {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  word-spacing: 1px;
}
.linktohover:hover {
  color: white !important;
  text-decoration: underline !important;
}
@media (max-width: 1780px) {
  .itemsContentIC {
    gap: 120px;
  }
}
@media (max-width: 1700px) {
  .itemsContentIC {
    gap: 90px;
  }
}
@media (max-width: 1640px) {
  .itemsContentIC {
    gap: 60px;
  }
}
@media (max-width: 1570px) {
  .itemsContentIC {
    gap: 40px;
  }
}
@media (max-width: 1510px) {
  .imgContentContainer {
    display: flex;
    flex-direction: column;
  }
  .itemsContentIC, .InputContentIC {
    margin-left: 200px;
  }
}

@media (max-width: 1370px) {
  .headerContentImgCont {
    position: relative;
  }
  .itemsContentIC {
    gap: 50px;
  }
}

@media (max-width: 940px) {
  .itemsContentIC {
    justify-content: center;
    margin-left: 0px;
  }
  .InputContentIC {
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }
}
@media (max-width: 680px) {
  .itemsContentIC {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .itemsContentICItem {
    align-items: center;
  }
}

@media (max-width: 480px) {
  .headerLandmap {
    font-size: 48px;
  }
}