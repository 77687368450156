@import "../../../../sass/variables";

.box {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 10px 25px;
  max-width: 1150px;
  margin: 0 auto;

  .item {
    width: 366px;
    //min-height: 500px;

    .itemImgBox {
      height: 270px;
      width: 360px;
      overflow: hidden;

      img {
        height: 100%;
        max-width: 300%;
      }
    }

    a {
      text-decoration: none;
    }

    .categorie {
      color: $kleur-cyan;
      margin-top: 16px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.875rem;
      padding: 0 2px;
    }

    .titel {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-size: 2rem;

    }

    .content {
      font-size: 0.875rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
    }

    .titel:hover, .itemImgBox:hover {
      cursor: pointer;
    }
  }
}

@media(min-width: 1150px) {
  .box {
    flex-direction: row;
    padding-bottom: 100px;
  }
}